import * as React from 'react';
import { DozukiHeader, DozukiHeaderProps, Permissions } from '@dozuki/web-js/components';
import { initializeChakraComponent } from 'Shared/chakra-initialize';
import { _js } from '@ifixit/localize';
import { apiClient } from 'Shared/api_client';

type DozukiHeaderComponentProps = Pick<
   DozukiHeaderProps,
   | 'siteLogoUrl'
   | 'siteTitle'
   | 'user'
   | 'permissions'
   | 'userTranslationSettings'
   | 'siteConfiguration'
   | 'showCustomHeader'
   | 'defaultSearchDocType'
> & {
   initialNotifications: DozukiHeaderProps['notifications'];
   initialTotalUnreadNotifications: DozukiHeaderProps['totalUnreadNotifications'];
};

type PermissionMappings = {
   [key: string]: string;
};

type App = {
   appKey: string;
   route: string;
   name: string;
};

const onRedirect = (url: string) => {
   window.location.href = url;
};

function DozukiHeaderComponent({
   initialNotifications,
   initialTotalUnreadNotifications,
   ...props
}: DozukiHeaderComponentProps) {
   const [notifications, setNotifications] = React.useState(initialNotifications);
   const [totalUnreadNotifications, setTotalUnreadNotifications] = React.useState(
      initialTotalUnreadNotifications
   );

   function handleMarkNotificationAsRead(notificationid: string) {
      setTotalUnreadNotifications(prevTotalUnreadNotifications => prevTotalUnreadNotifications - 1);
      setNotifications(
         notifications.filter(notification => notification.notificationid !== notificationid)
      );

      apiClient.get(`notifications?ids=${notificationid}`);
   }

   function handleMarkAllNotificationsAsRead() {
      setTotalUnreadNotifications(0);
      setNotifications([]);

      apiClient.delete(`notifications/all`);
   }

   React.useEffect(() => {
      const fontCss =
         'body { font-family: Inter, -apple-system, Segoe UI, Helvetica, Arial, sans-serif; }';
      let style = document.createElement('style');
      style.appendChild(document.createTextNode(fontCss));
      document.head.appendChild(style);
   }, []);

   const showCustomHeader = props?.showCustomHeader;
   const siteLogoUrl =
      showCustomHeader && !props.siteConfiguration['feature-dozuki-header-v3']
         ? undefined
         : props?.siteLogoUrl;
   const siteTitle =
      showCustomHeader && !props.siteConfiguration['feature-dozuki-header-v3']
         ? undefined
         : props?.siteTitle;
   const defaultSearchDocType = showCustomHeader ? 'all' : props?.defaultSearchDocType;
   const permissionMappings: PermissionMappings = {
      'creator-pro': 'canIViewCreatorPro',
   };

   const filterApps = (apps: App[] | undefined, permissions: Permissions) => {
      if (!apps) {
         return [];
      }
      return apps.filter((app: App) => {
         const name = permissionMappings[app.appKey] as keyof Permissions;
         return permissions && name ? permissions[name] : true;
      });
   };

   return (
      <DozukiHeader
         {...props}
         siteLogoUrl={siteLogoUrl}
         siteTitle={siteTitle}
         _js={_js}
         onRedirect={onRedirect}
         notifications={notifications}
         totalUnreadNotifications={totalUnreadNotifications}
         onMarkNotificationAsRead={handleMarkNotificationAsRead}
         onMarkAllNotificationsAsRead={handleMarkAllNotificationsAsRead}
         onUserTranslationsSettingsChange={handleUserTranslationsSettingsChange}
         defaultSearchDocType={defaultSearchDocType}
         colorSet={{
            background: '#ffffff',
            text: '#11161a',
            accent: '#f3f4f6',
         }}
      />
   );
}

async function handleUserTranslationsSettingsChange(value) {
   await apiClient.post('/translations/settings', value);
   window.location.reload();
}

initializeChakraComponent('DozukiHeaderComponent', DozukiHeaderComponent);
